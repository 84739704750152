#label-quality {
  text-align: left;

  li {
    list-style-type: none;
    margin-top: 1rem;
  }

  .description {
    li {
      list-style-type: disc;
    }
  }
  
  .logo-label {
    // width: 8rem;
    height: 8rem;
  }
}
