/*----------------------------------------------------------------------------*\
  Colors
\*----------------------------------------------------------------------------*/
.red {
  color: $red;
}

.blue {
  color: $blue;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.black {
  color: $black;
}

.white {
  color: $white;
}

.orange {
  color: $orange;
}

.purple {
  color: $purple;
}

.gray {
  color: $gray;
}

.cyan {
  color: $cyan;
}

.turquoise {
  color: $turquoise;
}

.light {
  color: $light;
}

.dark {
  color: $dark;
}

.bg-red {
  background-color: $red;
}

.bg-blue {
  background-color: $blue;
}

.bg-green {
  background-color: $green;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-black {
  background-color: $black;
}

.bg-white {
  background-color: $white;
}

.bg-orange {
  background-color: $orange;
}

.bg-purple {
  background-color: $purple;
}

.bg-gray {
  background-color: $gray;
}

.bg-cyan {
  background-color: $cyan;
}

.bg-turquoise {
  background-color: $turquoise;
}

.bg-light {
  background-color: $light;
}

.bg-dark {
  background-color: $dark;
}

// .fill-red {
//   fill: $red;
// }

// .fill-blue {
//   fill: $blue;
// }

// .fill-green {
//   fill: $green;
// }

// .fill-yellow {
//   fill: $yellow;
// }

// .fill-black {
//   fill: $black;
// }

// .fill-white {
//   fill: $white;
// }

// .fill-orange {
//   fill: $orange;
// }

// .fill-purple {
//   fill: $purple;
// }

// .fill-gray {
//   fill: $gray;
// }

// .fill-cyan {
//   fill: $cyan;
// }

// .fill-turquoise {
//   fill: $turquoise;
// }

// .fill-light {
//   fill: $light;
// }

// .fill-dark {
//   fill: $dark;
// }


/*----------------------------------------------------------------------------*\
  Utils
\*----------------------------------------------------------------------------*/
.pointer {
  cursor: pointer;
}

.li-none {
  list-style-type: none;
}

.space-nowrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

@for $px from 0 through 50 {
  .br-#{$px} {
    border-radius: $px + px;
  }
}

/*----------------------------------------------------------------------------*\
  Font
\*----------------------------------------------------------------------------*/
@for $i from 1 through 40 {
  .txt-#{$i} {
    font-size: ($i * 0.1) + rem;
  }
}

.txt-bold {
  font-weight: bold;
}

.txt-normal {
  font-weight: normal;
}

.txt-i {
  font-style: italic;
}

.txt-underline {
  text-decoration: underline;
}

.txt-no-deco {
  text-decoration: none;
}

.txt-uc {
  text-transform: uppercase;
}

.txt-lc {
  text-transform: lowercase;
}

.txt-capitalize {
  text-transform: capitalize;
}


/*----------------------------------------------------------------------------*\
  Alignments
\*----------------------------------------------------------------------------*/
.txt-l {
  text-align: left;
}

.txt-c {
  text-align: center;
}

.txt-r {
  text-align: right;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.valign-t {
  vertical-align: top;
}

.valign-m {
  vertical-align: middle;
}

.valign-b {
  vertical-align: bottom;
}

.fix-t {
  position: fixed;
  top: 0;
  bottom: auto;
}

.fix-b {
  position: fixed;
  top: auto;
  bottom: 0;
}

.fix-l {
  position: fixed;
  left: 0;
  right: auto;
}

.fix-r {
  position: fixed;
  left: auto;
  right: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}


/*----------------------------------------------------------------------------*\
  Clear
\*----------------------------------------------------------------------------*/
.clear {
  clear: both;
}

.clear-l {
  clear: left;
}

.clear-r {
  clear: right;
}


/*----------------------------------------------------------------------------*\
  Display
\*----------------------------------------------------------------------------*/
.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}


/*----------------------------------------------------------------------------*\
  Size
\*----------------------------------------------------------------------------*/
@for $percent from 1 through 100 {
  // width
  .w#{$percent} {
    width: #{$percent + "%"};
  }

  .max-w#{$percent} {
    max-width: #{$percent + "%"};
  }

  .min-w#{$percent} {
    min-width: #{$percent + "%"};
  }

  // height
  .h#{$percent} {
    height: #{$percent + "%"};
  }

  .max-h#{$percent} {
    max-height: #{$percent + "%"};
  }

  .min-h#{$percent} {
    min-height: #{$percent + "%"};
  }
}

@for $rem from 0 through 40 {
  // width
  .w#{$rem}rem {
    width: #{$rem + "rem"};
  }

  .max-w#{$rem}rem {
    max-width: #{$rem + "rem"};
  }

  .min-w#{$rem}rem {
    min-width: #{$rem + "rem"};
  }

  // height
  .h#{$rem}rem {
    height: #{$rem + "rem"};
  }

  .max-h#{$rem}rem {
    max-height: #{$rem + "rem"};
  }

  .min-h#{$rem}rem {
    min-height: #{$rem + "rem"};
  }
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}


/*----------------------------------------------------------------------------*\
  Space
\*----------------------------------------------------------------------------*/
@for $i from 0 through 40 {
  // margin
  .m-auto {
    margin: auto;
  }

  .m#{$i} {
    margin: ($i * 0.1) + rem;
  }

  .mt#{$i} {
    margin-top: ($i * 0.1) + rem;
  }

  .mb#{$i} {
    margin-bottom: ($i * 0.1) + rem;
  }

  .ml#{$i} {
    margin-left: ($i * 0.1) + rem;
  }

  .mr#{$i} {
    margin-right: ($i * 0.1) + rem;
  }

  // padding
  .p#{$i} {
    padding: ($i * 0.1) + rem;
  }

  .pt#{$i} {
    padding-top: ($i * 0.1) + rem;
  }

  .pb#{$i} {
    padding-bottom: ($i * 0.1) + rem;
  }

  .pl#{$i} {
    padding-left: ($i * 0.1) + rem;
  }

  .pr#{$i} {
    padding-right: ($i * 0.1) + rem;
  }
}


/*----------------------------------------------------------------------------*\
  Flex
\*----------------------------------------------------------------------------*/
.f {
  display: flex;
}

.f-col {
  display: flex;
  flex-direction: column;
}

.f-row {
  display: flex;
  flex-direction: row;
}

.f-wrap {
  display: flex;
  flex-wrap: wrap;
}

.f-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.f-1 {
  flex: 1;
}

.f-2 {
  flex: 2;
}

.f-start {
  display: flex;
  justify-content: flex-start;
}

.f-end {
  display: flex;
  justify-content: flex-end;
}

.f-between {
  display: flex;
  justify-content: space-between;
}

.f-center {
  display: flex;
  justify-content: center;
}

.f-around {
  display: flex;
  justify-content: space-around;
}

.f-align-stretch {
  align-items: stretch;
}

.f-align-center {
  align-items: center;
}

.f-align-self-center {
  align-self: center;
}

.f-align-start {
  align-items: flex-start;
}

.f-align-self-start {
  align-self: flex-start;
}

.f-align-end {
  align-items: flex-end;
}

.f-align-self-end {
  align-self: flex-end;
}
