.presta-img {
  display: block;
  max-width: 100%;
  height: auto;
}

.logo-presta {
  height: 4rem;
}

.icon-btn {
  border-radius: 15px;
  box-shadow: -2px 2px 5px darkgrey;
  width: 6.5rem;
  height: 9.5rem;
}

.card-header-logo {
  max-height: 3rem; 
  max-width: 3rem;
  fill: white;
}

.close-icon {
  font-size: 1.5rem;
  margin: .5rem;
}
