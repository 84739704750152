.agency-body {
  padding: 0 1.25rem
}

.agency-title {
  color: white;
  background-color: #0E95D7;
}

iframe{
  height: 20vh;

  // Window width >= 992px
  @include lg {
    height: 50vh;
  }
}