#header {
  .logo-title {
    height: 20rem;
    // background: url('../img/photos/piste.jpg');
    background: url('../img/photos/parc.jpg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
  }
  
  .logo {
    top: 50%;
    transform: translateY(25%);
    background-color: white;
    border-radius: 25px;
    width: 10rem;
    height: auto;
  }
  
  .title {
    top: 75%;
    right: 5%;
    transform: translateY(-50%);
    color: #fff;
  }
  
  .separator {
    width: 60%;
    margin-left: 20%;
    height: 2px;
    background-color: #0E95D7;
    margin-bottom: 2rem;
  }
  
  .link {
    color: #09d3ac;
    text-decoration: none;
  }
  
  .link:hover {
    color: #83ddcb;
    text-decoration: none;
  }

  .navlink {
    font-weight: 400;
    font-size: 1rem;
    margin: 0 1.4rem;

    @include lg {
      font-weight: 700;
      font-size: 1.2rem;
      margin: 0 1.4rem;
    }
  }

  .active {
    color: $blue;
  }

  .navbar-toggler {
    line-height: 0;
    border: none;
  }

  .covid-box {
    width: 100%;
    background-color: #dc35453b;
    padding: 1rem;
    position: relative;
    
    .covid-title-box {
      display: flex;

      .covid-title {
        font-size: 1.5rem;
        color: $red;
      }

      .chevron {
        font-size: 1.5rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: $red;
      }
    }
  }
}
