// #slider {
//   height: auto;

//   .slide {
//     height: 25rem;
//     justify-content: flex-center;
//   }

//   img {
//     max-height: 20rem;
//   }
// }
.slide {
  border-radius: 1.5rem;
}